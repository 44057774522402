import {Injectable} from '@angular/core';
import {ApiService} from '../../../core/services/api.service';
import {map, mergeMap} from 'rxjs/operators';
import {ThirdParty} from '../models/third-party.model';
import {PermissionService} from '../../../core/services/permission.service';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyService {
  thirdParties: object = {};

  constructor(private apiService: ApiService, private permissionService: PermissionService) {
  }

  public initThirdParties() {
    return this.getThirdParties().pipe(
      mergeMap(thirdParties => Object.keys(thirdParties).filter(item =>
        !item.includes('salesforce') &&
        // !item.includes('brokers') &&
        !item.includes('co-brokers')
      )),
      mergeMap(endpoint => {
        // if (this.permissionService.hasPermission(`thirdpartymodule.${endpoint}`)) {
        return this.getThirdParty(endpoint, {serializer: 'base'}).pipe(
          map(results => {
            if (Object.keys(results).includes('results') && Object.keys(results).includes('next')) {
              results = results.results;
            }
            results.forEach(result => (result['key'] = result['name']));
            this.thirdParties[endpoint] = results.sort((a, b) => {
              if (a.name === 'LIJST/LISTE RECORD CREDITS') {
                return 1;
              }
              if (b.name === 'LIJST/LISTE RECORD CREDITS') {
                return -1;
              }
              if (a.name === 'ANDERE/AUTRE') {
                return 1;
              }
              if (b.name === 'ANDERE/AUTRE') {
                return -1;
              }
              return a.name.localeCompare(b.name);
            });
          })
        );
      })
    );
  }

  public getThirdParties() {
    return this.apiService.get(`/api/third-parties/`);
  }

  public getThirdParty(endPoint, queryParams = {}) {
    let params = new HttpParams();
    for (const key of Object.keys(queryParams)) {
      params = params.set(key, queryParams[key]);
    }
    if (endPoint === 'notaries' || endPoint === 'brokers') {
      params = params.set('page_size', 999999)
    }
    return this.apiService.get(`/api/third-parties/${endPoint}/`, params);
  }

  public getThirdPartyInstance(thirdParty, key, value) {
    return this.thirdParties[thirdParty].find(object => {
      return object[key] === value;
    });
  }

  public getThirdPartyInstances(thirdParty) {
    return this.thirdParties[thirdParty];
  }

  public getNotaries(search: string = '', base: boolean = false) {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    if (base) {
      params = params.set('serializer', 'base');
    }
    params = params.set('page_size', 999999)
    return this.apiService.get(`/api/third-parties/notaries/`, params);
  }

  public getNotariesCallback(base: boolean = false) {
    return (search: string) => {
      return this.getNotaries(search, base).pipe(
        map((res) => {
          const results = res.results as Array<ThirdParty>;
          results.forEach(result => (result['key'] = result['name']));
          return results;
        })
      );
    };
  }

  public requestCoBroker(data: Object) {
    return this.apiService.post(`/api/third-parties/co-brokers/request/`, data);
  }

  public getBrokerUsers(id: number, isFilterLoanRequests=false) {
    let params = new HttpParams();
    params = params.set('isFilterLoanRequests', isFilterLoanRequests);
    return this.apiService.get(`/api/third-parties/brokers/${id}/users`, params);
  }

  public getBrokerByUsers() {
    return this.apiService.get(`/api/third-parties/brokers/broker-by-user`, );
  }

  public getIfShowBrokerOffice() {
    return this.apiService.get(`/api/third-parties/brokers/is-show-office`, );
  }

  public getBrokerOffices() {
    return this.apiService.get(`/api/third-parties/brokers/broker-offices`, );
  }

  public getBrokerAccessGroups() {
    return this.apiService.get(`/api/broker-access-groups/`, );
  }
}
