import { Address, Email, PhoneNumber } from '../../client/models/client.model';
import { Resource } from '../../../shared/models/resource/resource.model';

export class ContactPerson {
	id: number;
	firstName: string;
	lastName: string;
	role: Resource;
	addresses: Address[];
	emails: Email[];
	phoneNumbers: PhoneNumber[];

	constructor(data?: any) {
		if (!(data === undefined || data === null)) {
			this.id = data.id || null;
			this.firstName = data.firstName || '';
			this.lastName = data.lastName || '';
			this.role = new Resource(data.role);
			this.addresses = data.addresses ?? [];
			this.emails = data.emails ?? [];
			this.phoneNumbers = data.phoneNumbers ?? [];
		}
	}
}

export class Broker {
	id: string;
	name: string;
	fsmaStatus: Resource;
	companyType: Resource;
	accountManager: ContactPerson;
	mainContact: ContactPerson;
	vatNumber: string;
	bankAccount: string;
	addresses: Address[];
	emails: Email[];
	phoneNumbers: PhoneNumber[];
	commissionEmail: Email;
	commissionClass: any;
	confiscateCommission: boolean;
	isActive: boolean;
	parent: number;
	brokerType: number;
	externalId: number;
	coBrokers: Array<number>;
	isCoBroker: boolean;

  outstandingDebt: number;

	constructor(data?: any) {
		if (!(data === undefined || data === null)) {
			this.id = data.id || undefined;
			this.name = data.name || '';
			this.accountManager = new ContactPerson(data.accountManager);
			this.mainContact = new ContactPerson(data.mainContact);
			this.companyType = new Resource(data.companyType);
			this.fsmaStatus = new Resource(data.fsmaStatus);
			this.vatNumber = data.vatNumber || '';
			this.bankAccount = data.bankAccount || '';
			this.addresses = data.addresses ?? [];
			this.emails = data.emails ?? [];
			this.phoneNumbers = data.phoneNumbers ?? [];
			this.commissionEmail = new Email(data.commissionEmail);
			this.commissionClass = data.commissionClass;
			this.confiscateCommission = data.confiscateCommission || undefined;
			this.isActive = data.isActive === false ? false : true;
			this.parent = data.parent || null;
			this.brokerType = data.brokerType || null;
			this.externalId = data.externalId || '';
			this.coBrokers = data.coBrokers || [];
			this.isCoBroker = data.isCoBroker === false ? false : true;
			this.outstandingDebt = data.outstandingDebt || null;
		}
	}
}
